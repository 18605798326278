<template>
  <div>
    <v-expand-transition>
      <v-card v-if="merged && !viewAnyway">
        <v-card-text class="title text-center py-13">
          <div>The profile you are looking for was a duplicate and is now merged.</div>
          <div>Find the merged profile at:</div>
          <v-btn color="color3" text :to="merged">
            {{mergedURL}}
          </v-btn>
          <div class="pt-6">
            <v-btn color="color3" text x-small v-if="user && user.vbl" @click.stop="viewAnyway = true">
              View profile anyway
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <v-expand-transition>
      <v-card v-if="!(merged && !viewAnyway)" color="grey lighten-3" flat light tile>
        <v-toolbar :color="!embeded ? 'color2 color2Text--text' : ''" :dense="embeded">
          <v-toolbar-title class="title">{{name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              v-if="player && view.vblx"
              color="color1 color1Text--text"
              fab small
              @click.stop="getFullProfile(true)"
              :loading="fullLoading"
              class="ma-0"
            >
              <v-icon small>fas fa-redo-alt</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              v-if="player && view.admin"
              color="color3 color3Text--text"
              fab small
              :id="window"
              @click.stop="editClick"
              :loading="fullLoading"
              class="ma-0"
            >
              <v-icon small v-if="window !== 1">fas fa-cog</v-icon>
              <v-icon small v-else>fas fa-portrait</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-toolbar>
        <loading-bar :value="loading || fullLoading"></loading-bar>
        <!-- Admin Status And Membership Stuff -->
        <v-expand-transition>
          <v-container fluid class="py-0">
            <v-expand-transition>
              <v-row dense v-if="!view.admin">
                <v-col cols="12" class="caption my-0 pa-0">
                  <claim-dialog
                    :player="player"
                    :playerProfileId="playerProfileId"
                    @just-claimed="onClaimed">
                  </claim-dialog>
                </v-col>
              </v-row>
            </v-expand-transition>
            <v-row dense v-if="view.vbl"><!-- VBL ADMIN STUFF -->
              <v-col cols="12" class="caption my-1 pa-0 d-flex justify-space-between">
                <claim-dialog
                  :player="player"
                  :playerProfileId="playerProfileId"
                  :view="view"
                  @just-claimed="onClaimed"
                ></claim-dialog>
                <ntdp-optin
                  v-if="player && player.recruitFields && view.self && !noNtdp"
                  :player="player"
                ></ntdp-optin>

                <!-- {{view}} -->
              </v-col>
            </v-row>
            <v-divider v-if="player && player.recruitFields && view.self && !noNtdp"></v-divider>
            <!-- MEMBERSHIP STUFF -->
            <v-row dense v-if="memberId && theme.membership">
              <v-col cols="12" class="title pb-0" style="display: inline-flex; align-items: center;">
                Member {{memberId}}
                <v-skeleton-loader
                  :loading="statusLoading"
                  transition="scale-transition"
                  type="chip"
                  class="ml-3"
                >
                  <v-chip
                    :class="{clickable: !!(userMember)}"
                    :color="`${mStatus && mStatus.status === 'Active' ? 'success' : 'error'} white--text`"
                    @click.stop="goto('membership')"
                  >
                    {{mStatus ? mStatus.status : ''}}
                  </v-chip>

                </v-skeleton-loader>
                <v-btn
                  v-if="memberBtn && currentStep.type !== 'membership' && window !== 2"
                  color="color3 color3Text--text"
                  @click.stop="goto('membership')"
                  text
                >
                  {{ memberBtn }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="grey--text text--lighten-1 pt-0">
                <v-skeleton-loader
                  :loading="statusLoading"
                  transition="scale-transition"
                  :class="statusLoading ? 'py-3' : null"
                  width="150"
                  height="24"
                  type="text"
                >
                  <div>{{mStatus ? mStatus.level : ''}} Member</div>
                </v-skeleton-loader>
              </v-col>
              <!-- RENEW BUTTON-->
              <v-expand-transition>
                <v-col cols="12" v-if="memberBtn && currentStep.type !== 'membership' && false">
                  <v-btn
                    v-if="window !== 2"
                    color="color3 color3Text--text"
                    block
                    @click.stop="goto('membership')"
                  >
                    {{ memberBtn }}
                  </v-btn>
                </v-col>
              </v-expand-transition>
            </v-row>
            <!-- END MEMBERSHIP STUFF -->
          </v-container>
        </v-expand-transition>
        <v-window v-model="window" touchless>
          <v-window-item v-for="s in steps" :key="s.key">
            <!-- Profile -->
            <profile-display
              v-if="s.key === 0"
              :player="player"
              :profilePic="profilePic"
              :userStatus="userStatus"
              :permitted="permitted"
              :view="view"
              :loading="loading || fullLoading"
              :bidBadges="bids"
            ></profile-display>

            <!-- Editor -->
            <template v-if="view.admin">
              <!-- Reg Info -->
              <v-card-text v-if="s.type === 'basic'" class="pt-0" :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
                <profile-editor
                  v-if="player"
                  :player="player"
                  :active="window === s.key"
                  :view="view"
                ></profile-editor>
              </v-card-text>
              <!-- Membership -->
              <v-card-text v-else-if="s.type === 'membership'" class="pt-0" :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
                <v-btn
                  color="color3"
                  text small
                  @click.stop="window = 0"
                >
                  <v-icon class="mr-2" small>fas fa-chevron-left</v-icon>
                  Back to profile
                </v-btn>
                <membership-manager
                  ref="levelSelector"
                  :program="program"
                  :loading="programLoading || fullLoading"
                  :member="member"
                  :active="window === s.key"
                  @add-level="getProgram"
                  @status-change="getMemberStatus"
                ></membership-manager>
              </v-card-text>
            </template>
          </v-window-item>
        </v-window>
        <!-- Profile Nav -->
        <v-bottom-navigation
          v-if="view.admin && window > 0 && false"
          fixed shift
          :input-value="window > 0"
          v-model="window"
          background-color="color2 color2Text--text"
          height="56"
        >
          <v-btn v-for="step in steps.filter(f => !f.hide)" :key="step.key" :value="step.key" color="color2Text--text">
            <span>{{step.text}}</span>
            <v-icon>{{step.icon}}</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-card>
    </v-expand-transition>
    <div class="text-center" v-if="user && user.vbl && !(merged && !viewAnyway)">
      <v-select
        :items="['Self', 'Coach']"
        v-model="viewSelect"
        label="View As"
        color="color3"
        item-color="color3"
      ></v-select>
    </div>
    <ntdp-drawer v-if="user && user.ntdp && window === 0" :player="player" :view="view"></ntdp-drawer>
  </div>
</template>

<script>
import * as mutations from '../../store/MutationTypes'
import { mapGetters } from 'vuex'
import ClaimDialog from '@/components/Player/ClaimDialog'
import ProfileDisplay from '@/components/Player/ProfileDisplay'
import PlayerProfile from '@/classes/PlayerProfile'
import { firstBy } from 'thenby'
import badges from '@/classes/Badges'
const MembershipManager = () => import('@/components/Membership/Member/MembershipManager.vue')
const ProfileEditor = () => import('@/components/Player/Editor/ProfileEditor.vue')
const NtdpDrawer = () => import('@/New/NtdpDrawer.vue')
const NtdpOptin = () => import('@/New/NtdpOptin')

export default {
  props: ['playerProfileId', 'sbid', 'playerName', 'embeded', 'memberId', 'profilePic'],
  data () {
    return {
      player: null,
      tab: 1,
      loading: false,
      programLoading: false,
      statusLoading: false,
      permitting: false,
      window: 0,
      profile: null,
      fullLoading: false,
      mStatus: null,
      program: null,
      permitted: false,
      profileFull: false,
      viewSelect: 'Self',
      start: null,
      claimed: false,
      bids: [],
      viewAnyway: false
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView', 'subdomain', 'theme', 'connectionId']),
    noNtdp () {
      return this.theme && this.theme.props.includes('no-ntdp')
    },
    view () {
      return this.viewSelect === 'Coach' ? {
        vbl: false,
        admin: false,
        public: false,
        college: true,
        allAdmin: false
      } : {
        vbl: this.user && this.user.vbl && !this.publicView,
        admin: this.userStatus === 13 && !this.publicView,
        public: this.publicView,
        college: this.user && this.user.isCollegeCoach && !this.publicView && this.player && this.player.isJunior,
        td: this.user && this.user.isTD,
        self: this.user && this.player && this.player.users && this.player.users.includes(this.user.id),
        get allAdmin () {
          return this.vbl || this.admin || this.college || this.td
        }
      }
    },
    merged () {
      return this.player && this.player.profileType && this.player.profileType.toLowerCase().startsWith('merge') && this.player.profileType.replace('Merged with ', '')
    },
    mergedURL () {
      return this.merged && `${window.location.origin}/player/${this.merged}`
    },
    name () {
      return this.player ? this.player.fullname : this.playerName
    },
    fullProfile () {
      return this.player
    },
    profileStatus () {
      return this.player && this.player.users && this.player.users.length ? 'Claimed' : 'Unclaimed'
    },
    userMember () {
      return this.user && this.user.member
    },
    userStatus () {
      if (!this.user) return 0 // 'No User'
      // if (this.user.vbl && !this.publicView) return 13 // 'VBL Admin'
      if (!!this.userMember && this.userMember.id === this.memberId) return 13
      return this.permitted || (this.player && this.player.users && this.player.users.includes(this.user.id)) ? 13 : 0 // 'Permitted' : 'Nor Permitted'
    },
    memberBtn () {
      // if ((this.userMember || (this.user && this.user.vbl)) && this.mStatus) {
      console.log(this.userMember)
      if (this.member && this.mStatus) {
        return this.mStatus.status === 'Expired' ? 'Renew Now!' : this.mStatus.status === 'Inactive' ? 'Activate Your Membership' : 'Extend Your Membership'
      }
      return null
    },
    member () {
      if (this.userStatus && this.mStatus && this.player) {
        const m = new PlayerProfile(this.$VBL, this.player.basicInfoDto)
        m.id = this.mStatus.id
        m.level = this.mStatus.level
        m.levelId = this.mStatus.levelId
        m.status = this.mStatus.status
        return m
      }
      return null
    },
    steps () {
      var i = 0
      return [
        {
          key: i++,
          icon: 'fas fa-user-circle',
          text: 'Profile'
        },
        {
          key: i++,
          title: 'Tournament Registration Info',
          icon: 'fas fa-money-check-edit',
          type: 'basic',
          text: 'Edit'
        },
        {
          key: i++,
          title: 'Privacy Settings',
          icon: 'fas fa-cog',
          type: 'settings',
          text: 'Privacy'
        },
        {
          key: i++,
          title: 'Key Volleyball Metrics',
          icon: 'fas fa-volleyball-ball',
          type: 'metrics',
          text: 'Volleyball'
        },
        {
          key: i++,
          title: 'Acadmeic Information',
          icon: 'fas fa-user-graduate',
          type: 'academics',
          text: 'Academics',
          hide: this.player && !this.player.recruitFields
        },
        {
          key: i++,
          title: 'Additional Contacts',
          icon: 'fas fa-users-medical',
          text: 'Contacts',
          type: 'contacts',
          hide: this.player && !this.player.isJunior && !this.player.recruitFields
        },
        {
          key: i++,
          icon: 'fas fa-users-cog',
          text: 'Membership',
          type: 'membership',
          hide: !this.member
        }
      ]
    },
    btmNav () {
      return this.window > 0
    },
    currentStep () {
      return this.steps.find(f => f.key === this.window)
    },
    gradYear () {
      return this.player && this.player.gradYear && this.player.recruitFields ? this.player.gradYear : ''
    }
  },
  methods: {
    editClick () {
      if (this.window === 1) {
        this.window = 0
        return
      }
      if (this.userStatus !== 13) {
        this.$refs.dialog.open = true
        return
      }
      this.goto(1)
    },
    onClaimed () {
      console.log('onClaimed')
      this.claimed = true
    },
    getFullProfile (force) {
      return new Promise((resolve, reject) => {
        if (this.profileFull && !force) return resolve()
        this.fullLoading = true
        this.$VBL.player.getProfile(this.playerProfileId, this.memberId, true)
          .then(r => {
            this.player.update(r.data)
            this.profileFull = true
            resolve()
          })
          .catch(e => {
            console.log(e.response)
            reject(e)
          })
          .finally(() => { this.fullLoading = false })
      })
    },
    getProfile () {
      console.log('getProfile')
      this.loading = true
      if (this.memberId && this.theme.membership) this.getMemberStatus()
      if (!this.playerName) {
        this.$store.commit(mutations.LOADING, 'finishes.get')
      }

      this.$VBL.player.getProfile(this.playerProfileId, this.memberId)
        .then(response => {
          this.player = new PlayerProfile(this.$VBL, response.data)
        })
        .catch(error => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
          this.$store.commit(mutations.LOADING_COMPLETE, 'member-levels')
          this.$store.commit(mutations.LOADING_COMPLETE, 'finishes.get')
        })
    },
    memberCheck () {
      return new Promise((resolve, reject) => {
        if (this.theme.membership && this.$route.name === 'player-profile') {
          this.$VBL.http.get(`/membership/check/${this.playerProfileId}`)
            .then(r => {
              if (r.data) {
                const newParams = Object.apply({}, this.$route.params)
                newParams.id = newParams.memberId = r.data
                return resolve({ name: 'member-profile', params: newParams, query: this.$route.query })
              } else {
                return resolve()
              }
            })
        } else {
          return resolve()
        }
      })
    },
    bidCheck () {
      console.log('getBids')
      this.$VBL.player.getBids(this.player.id)
        .then(r => {
          this.bids = []
          r.data.sort(firstBy('sb')).forEach(m => {
            if (m.sb.toLowerCase() === 'aaux') {
              this.bids.push(...m.tournaments.map(t => {
                const sb = t.name.toLowerCase().includes('west') ? 'aauWCJO' : 'aauBob'
                return {
                  sb: sb,
                  src: badges.bids[sb.toLowerCase()],
                  tournaments: [t]
                }
              }))
            } else {
              m.src = badges.bids[m.sb.toLowerCase()]
              m.tournaments.forEach(t => {
                const x = m.sb.toLowerCase() === 'aau' ? t.name.toLowerCase().includes('west') ? 'aauWCJO' : 'aauBob' : null
                if (x) t.src = badges.bids[x.toLowerCase()]
              })
              this.bids.push(m)
            }
          })
          this.bids = this.bids.filter(f => !!f.src)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getMemberStatus () {
      this.statusLoading = true
      this.$VBL.get.members(this.subdomain, this.memberId)
        .then(r => {
          this.mStatus = r.data && r.data.length === 1 && r.data[0]
          this.statusLoading = false
        })
    },
    getProgram (add) {
      return new Promise((resolve, reject) => {
        if ((this.program && !add) || !this.userStatus || !this.subdomain) return resolve()
        this.programLoading = true
        this.$VBL.get.membership(this.subdomain, add)
          .then(r => {
            this.program = r.data
            this.program.levels && this.program.levels.sort(firstBy('order'))
            resolve()
          })
          .catch(e => {
            console.log(e.response)
            reject(e)
          })
          .finally(() => {
            this.programLoading = false
          })
      })
    },
    goto (i) {
      if (typeof i === 'string') {
        const m = this.steps.find(f => f.type === i)
        i = m.key
      }
      if (this.userStatus === 0) return
      const a = this.getProgram()
      const b = this.getFullProfile()
      Promise.all([a, b]).then(() => {
        this.$nextTick(() => {
          this.window = i
        })
      })
    },
    permit () {
      this.permitting = true
      this.$VBL.player.permit(this.playerProfileId, this.memberId)
        .then(r => {
          if (r.data) {
            this.permitted = true
          } else {
            this.permitted = false
          }
        })
        .finally(() => { this.permitting = false })
    }
  },
  watch: {
    '$route.query.levels': function (v) {
      if (v) {
        this.goto(9)
        this.$router.replace(this.$route.path)
      }
    },
    user: function (val) {
      this.getProfile()
      if (val && this.userStatus === 0) {
        this.permit()
      }
    },
    tab: function (v) {
      this.window = v
    },
    player: function (v) {
      if (v && this.user && this.userStatus === 0) {
        this.permit()
      }
    },
    userStatus: function (n, o) {
      n && this.start && this.goto(this.start)
    },
    permitted: function (v) {
      v && this.claimed && this.editClick()
    },
    'player.id': 'bidCheck'
  },
  mounted () {
    this.start = this.$route.query.tab
    this.memberCheck()
      .then(r => {
        if (r) {
          this.$router.replace(r)
        } else {
          this.getProfile()
        }
      })
  },
  components: {
    ProfileDisplay,
    ClaimDialog,
    MembershipManager,
    ProfileEditor,
    NtdpDrawer,
    NtdpOptin
  }
}
</script>

<style scoped>
.link {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
td {
  padding: 0 4px !important;
}
</style>
